.short-separator-line {
    border: 0;
    border-top: 2px solid #ccc;
    width: 50%; /* Adjust the width as needed */
    margin: 0 25%; /* Space between text and the line */
    margin-top: 60px;
    flex-grow: 1;
    opacity: 0.7;
}


.lanas-image {
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Hero Section */
.hero-section {
    line-height: 1.6;
    background: linear-gradient(to right, #2f5c69, #2f6c69);
    color: white;
    text-align: center;
    padding: 3rem 1rem;
}

.hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero-section p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
}

/* Call to Action Section */
.cta-section {
    background: linear-gradient(to right, #2f5c69, #2f6c69);
    color: white;
    text-align: center;
    padding: 2rem 1rem;
}

.cta-section h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.cta-section button {
    background: #ffcc00;
    color: #004080;
    border: none;
    padding: 0.7rem 2rem;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.cta-section button:hover {
    background: #e6b800;
}

#main {
  scroll-margin-top: 80px; /* Matches the navbar height */
}

.product-page {
  margin-top: 80px;
}
.contact-page {
  margin-top: 80px;
}

/* Container for the whole page 
.product-page {
  background: url('../../assets/images/bkg/cool-underwater.png') no-repeat center center fixed;
}

.product-transparent-box {
  color: #333;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  max-width: 90%;
  margin: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
*/

/* Top Seller Card Styling */
.top-seller-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(145deg, #fdfdfd, #ececec); /* Elegant gradient */
  border: 2px solid #2f6c69; /* Highlighted border */
  border-radius: 5px; /* Smooth, rounded corners */
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow */
  overflow: hidden;
  max-width: 400px; /* Max width for cards */
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative; /* Needed for badge placement */
  padding: 50px; /* Space inside the card */
  text-align: left;
}

/* Title for Top Seller Card */
.top-seller-title {
  font-size: 1.8rem; /* Slightly larger title */
  font-weight: bold;
  color: #2f6c69; /* Highlight color */
  margin-bottom: 15px;
}

/* Description for Top Seller Card */
.top-seller-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
  text-align: center;
  margin-bottom: 20px;
  text-align: left;
}

/* Price Styling */
.top-seller-price {
  font-size: 1.5rem;
  color: #003366; /* Dark blue for emphasis */
  font-weight: bold;
  margin: 10px 0;
}

/* Call-to-Action Button */
.top-seller-button {
  display: inline-block;
  padding: 10px 20px;
  background: #2f6c69; /* Highlight color */
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.top-seller-button:hover {
  background: #1c4c4a; /* Darker highlight on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-seller-card {
    max-width: 90%; /* Cards take up more space on smaller screens */
  }
}


/* Top Seller Badge */
.top-seller-badge {
  position: absolute;
  top: 0px;
  left: 15px;
  background: #ffcc00; /* Gold badge */
  color: #333;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 5px; /* Pill shape */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase; /* Make text uppercase */
  z-index: 1; /* Ensure it appears above the card */
}

/* Top Seller Card Hover Effect */
.top-seller-card:hover {
  transform: translateY(-10px); /* Smooth lift effect */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  background: linear-gradient(145deg, #f5f5f5, #dedede); /* Lighter gradient on hover */
}

.sale-badge {
  position: absolute;
  top: 0px;
  left: 15px;
  background: #ff4d4d; /* Bright red for sale */
  color: #fff; /* White text for contrast */
  font-size: 0.9rem;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 5px; /* Pill shape */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-transform: uppercase; /* Make text uppercase */
  z-index: 1; /* Ensure it appears above the card */
  animation: pulse 2s infinite; /* Attention-grabbing pulse animation */
}

/* Pulse Animation for Sale Badge */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01);
  }
}

.card-list {
    margin-top: 80px;
}

/* Adjusting Other Styles to Fit New Badge */
.card-list .card {
  position: relative; /* Ensures the badge positions correctly */
}


.intro {
  font-size: 1.2rem;
  text-align: justify;
  margin-top: 50px;
  color: #555; /* Neutral gray for secondary text */
}

.intro_p {
  font-size: 1.2rem;
  text-align: justify;
  margin-top: 10px;
  color: #555; /* Neutral gray for secondary text */
}

/* Style for unordered lists (ul) */
.top-seller-description ul {
  list-style: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

/* Style for list items (li) */
.top-seller-description ul li {
  font-size: 1.1em; /* Slightly larger text */
  line-height: 1.8; /* Increase line height for readability */
  margin-bottom: 10px; /* Space between list items */
  position: relative; /* Allow for custom bullet creation */
  padding-left: 25px; /* Space for custom bullet */
}

/* Add custom bullet */
.top-seller-description ul li::before {
  content: "⋄"; /* Unicode character for a dot (bullet) */
  color: #003366; /* Dark blue color for bullet */
  font-size: 1.5em; /* Larger bullet */
  position: absolute; /* Position it to the left of the list item */
  left: 0; /* Align bullet to the left */
  top: 50%; /* Vertically center the bullet */
  transform: translateY(-50%); /* Fine-tune vertical alignment */
}

/* Style for strong tags inside list items */
.top-seller-description ul li strong {
  font-weight: bold; /* Bold the strong text */
  color: #2f5c69; /* Matching color with bullet */
}

.products-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: justify;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #2f5c69; /* Deep blue for professionalism */
  position: relative; /* Needed for the line to position properly */
}


/* Title and Intro */
.line-smooth-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #2f5c69; /* Deep blue for professionalism */
}

.line-smooth-main {
    margin-bottom: 30px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.05); /* Enhanced shadow on hover */
    background: linear-gradient(145deg, #f7f7f7, #e8e8e8); /* Lighter gradient on hover */
}

.line-smooth-main:hover {
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
    background: linear-gradient(145deg, #f7f7f7, #e8e8e8); /* Lighter gradient on hover */
}

/* Expandable line styling */
.line-smooth {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    transition: background-color 0.3s ease;
}

.line-smooth {
  padding: 15px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

/* Arrow styling */
.arrow-line-smooth {
    color: #2f5c69;
    font-size: 20px;
    transition: transform 0.3s ease;
}

.arrow-line-smooth.rotate {
    transform: rotate(180deg);
}

/* Smooth collapsing and expanding with max-height and transition */
.cards-wrapper-smooth {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
  padding: 0 15px; /* Ensure padding is part of transition */
}
