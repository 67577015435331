/* ImageSlider.css */
.slider {
  margin-top: 80px;
  position: relative;
  width: 100%;
  height: 800px; /* Default height for larger screens */
  overflow: hidden;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .slider {
    height: 200px; /* Reduce height for mobile */
  }

  .slide {
    /* Add responsiveness for images in the slider */
    background-size: cover;
    background-position: center;
  }

  .nav-button {
    font-size: 20px; /* Smaller nav buttons */
    padding: 8px; /* Smaller padding */
  }

  .nav-button.left {
    left: 10px;
  }

  .nav-button.right {
    right: 10px;
  }

  .nav-button:hover {
    background: rgba(0, 0, 0, 0.7); /* Darker on hover */
  }

  .dots {
    bottom: 15px; /* Move dots slightly higher on mobile */
  }

  .dot {
    height: 8px; /* Smaller dots */
    width: 8px; /* Smaller dots */
  }
}

/* Desktop styles (keeping your current setup) */
.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
}

.nav-button.left {
  left: 10px;
}

.nav-button.right {
  right: 10px;
}

.nav-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active,
.dot:hover {
  background-color: white;
}

.slide-text-0 {
  position: absolute;
  color: white;
  bottom: 15%; /* Adjust for better scaling */
  left: 7%; /* Adjust for responsive positioning */
  transform: translateX(0); /* Remove 50% offset for better alignment */
  text-align: left;
}

.slide-text-1 {
  position: absolute;
  color: white;
  top: 15%; /* Adjust for better scaling */
  left: 7%; /* Adjust for responsive positioning */
  transform: translateX(0); /* Remove 50% offset for better alignment */
  text-align: left;
}

.slide-text-0 h2,
.slide-text-1 h2 {
  font-family: 'Dela Gothic One', sans-serif;
  margin: 0;
  font-weight: 300; /* This won’t reduce boldness */
  font-size: 2.2vw; /* Scale font size relative to the viewport width */
  white-space: normal; /* Allow text wrapping */
  width: auto; /* Remove width restriction */
  max-width: 90%; /* Optional: limit text to avoid overflow */
  word-break: break-word;
  text-align: left;
}

.slide-text-2 {
  position: absolute;
  color: black;
  bottom: 18%; /* Adjust for better scaling */
  left: 10%; /* Adjust for responsive positioning */
  transform: translateX(0); /* Remove 50% offset for better alignment */
  text-align: left;
}

.slide-text-2 h2 {
  font-family: 'Dela Gothic One', sans-serif;
  margin: 0;
  font-weight: 300; /* This won’t reduce boldness */
  font-size: 1.5vw; /* Scale font size relative to the viewport width */
  white-space: normal; /* Allow text wrapping */
  width: auto; /* Remove width restriction */
  max-width: 70%; /* Optional: limit text to avoid overflow */
  word-break: break-word;
  text-align: left;
}

.slide-text-3 {
  position: absolute;
  color: white;
  bottom: 18%; /* Adjust for better scaling */
  left: 60%; /* Adjust for responsive positioning */
  text-align: right !important;
}

.slide-text-3 h2 {
  font-family: 'Dela Gothic One', sans-serif;
  margin: 0;
  font-weight: 300; /* This won’t reduce boldness */
  font-size: 2.2vw; /* Scale font size relative to the viewport width */
  white-space: normal; /* Allow text wrapping */
  width: auto; /* Remove width restriction */
  text-align: right;
  max-width: 80%; /* Optional: limit text to avoid overflow */
  word-break: break-word;
}

.slide-text-4 {
  position: absolute;
  color: white;
  bottom: 18%; /* Adjust for better scaling */
  right: 10%; /* Adjust for responsive positioning */
  transform: translateX(0); /* Remove 50% offset for better alignment */
  text-align: right !important;
}

.slide-text-4 h2 {
  font-family: 'Dela Gothic One', sans-serif;
  margin: 0;
  font-weight: 300; /* This won’t reduce boldness */
  font-size: 2.2vw; /* Scale font size relative to the viewport width */
  white-space: normal; /* Allow text wrapping */
  width: auto; /* Remove width restriction */
  text-align: right;
}

