/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the whole page */
.contact-page {
  height: 87vh; /* Full height of the viewport */
  background: url('../../assets/images/bkg/shaking_hands.jpeg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contact us form container */
.contact-us {
  height: 85vh;
  color: #333;
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.85); /* Semi-transparent white background for readability */
  border-radius: 10px;
  max-width: 90%;
  margin: 50px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Header styling */
.contact-us h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #003366;
  text-align: center;
  margin-bottom: 20px;
}

/* Section title styling with separator */
.section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #555;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

/* Section styling for contact, details, and bank sections */
.contact-section,
.details-section,
.bank-section {
  margin-bottom: 30px;
}

/* Styling for the paragraphs inside contact, details, and bank sections */
.contact-section p,
.details-section p,
.bank-section p {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.8;
  color: #666;
}

/* Styling for the contact section links */
.contact-section a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.contact-section a:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .contact-us {
    padding: 20px;
  }

  .contact-us h2 {
    font-size: 18px;
  }

  .section-title {
    font-size: 10px;
  }

  .contact-section p,
  .details-section p,
  .bank-section p {
    font-size: 8px;
  }
}
