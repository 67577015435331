* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;  /* Full viewport height */
  text-align: center;  /* Center text horizontally */
  font-size: 3rem;  /* Adjust font size as needed */
  color: black; /* Optional: Change text color */
}

.home,
.services,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.home {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.marketing {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.consulting {
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

/* Footer Styles */
.footer {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  color: #fff; /* White text */
  padding: 20px 0; /* Padding for spacing */
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 1.0rem;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-contact {
  font-size: 0.8rem;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-contact a {
  color: #fff;
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.footer-copyright {
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
  .footer-contact {
    margin-bottom: 10px;
  }
}
