/* Main Container */
.usbl-info-container {
  width: 90%;
  margin: 0 auto;
  line-height: 1.6;
}

.usbl-section {
  max-width: 1400px;
  margin: 0 auto;
  margin-top : 50px;
  margin-bottom : 50px; 
  padding : 20px;
  padding-top: 20px;
  padding-bottom: 40px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
  background: linear-gradient(145deg, #f7f7f7, #e8e8e8); /* Lighter gradient on hover */
}

.usbl-section h2 {
  font-size: 28px;
  color: #2f5c69;
  margin-bottom: 20px;
}

.usbl-section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
}

.usbl-section ol {
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
  padding-left: 20px;
  font-weight: normal;
}

.usbl-section li {
  margin-bottom: 10px;
  font-weight: normal;
}

/* Styling for each section's title */
.usbl-section strong {
  font-weight: bold;
}

/* Add a subtle hover effect for the whole section */
.usbl-section:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .usbl-info-container {
    width: 95%;
  }
  
  .usbl-section h2 {
    font-size: 24px;
  }
  
  .usbl-section p,
  .usbl-section ol {
    font-size: 14px;
  }
}


/* Container to align text and image in a row */
.usbl-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* Style for the text part */
.usbl-text {
  flex: 1;
  padding-right: 20px; /* Add space between text and image */
  max-width: 60%; /* Ensure text doesn't take up too much space */
}

/* Style for the image */
.usbl-image {
  flex: 1;
  max-width: 35%; /* Ensure image takes up the appropriate space */
  text-align: right; /* Align image to the right */
}

/* Image style */
.pos-image {
  width: 100%;
  max-width: 500px; /* Set a max width to prevent the image from becoming too large */
  height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .usbl-content {
    flex-direction: column; /* Stack text and image on top of each other for smaller screens */
    align-items: center;
  }
  
  .usbl-text {
    max-width: 100%; /* Ensure the text section takes full width */
    padding-right: 0; /* Remove padding on smaller screens */
  }

  .usbl-image {
    max-width: 80%; /* Reduce the image size on small screens */
    text-align: center; /* Center the image */
  }
}
