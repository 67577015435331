
/* USBLModemDetails.css */
.spec-header-image {
  text-align: center;
  margin: 60px;
}

.spec-header-image-img {
  width: 100%;  /* Adjust the width as necessary */
  max-width: 400px; /* Set a maximum width to ensure it doesn't stretch too much */
  height: auto;  /* Keep the aspect ratio */
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

.spec-header {
  font-size: 32px;
  text-align: center;
  color: #2f5c69;
  margin-bottom: 20px;
}

.spec-item {
  background: linear-gradient(145deg, #fafafa, #f1f1f1);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.full-width {
  flex: 0 0 100%; /* Ensures full-width for this section */
}

.spec-items-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.half-width {
  flex: 0 0 32%; /* Each section takes up 48% of the width */
}

.half-width-50 {
  flex: 0 0 48%; /* Each section takes up 48% of the width */
}

.spec-title {
  font-size: 24px;
  color: #2f5c69;
  margin-bottom: 10px;
}

.spec-details {
  list-style: none;
  padding: 0;
  color: #555;
}

.spec-details li {
  font-size: 16px;
  padding: 5px 0;
  border-bottom: 1px solid #e0e0e0;
}

.spec-details li:last-child {
  border-bottom: none;
}

/* Adjust layout for smaller screens */
@media (max-width: 768px) {
  .spec-container {
    padding: 15px;
  }

  .spec-items-row {
    flex-direction: column;
  }

  .spec-item {
    flex: 1 1 100%;
  }

  .half-width {
    flex: 0 0 100%; /* Stack the sections on smaller screens */
  }
}

.spec-item .learn-more-button {
  display: inline-block;      /* Ensure it's treated as a block-level element for centering */
  text-align: center;         /* Center the text inside the button */
  margin: 20px auto;          /* Center horizontally with auto margins */
  padding: 10px 20px;         /* Add padding for better appearance */
  background-color: #2f5c69;  /* Example background color */
  color: white;               /* Text color */
  text-decoration: none;      /* Remove underline */
  border-radius: 5px;         /* Rounded corners */
  font-size: 16px;            /* Adjust font size */
  cursor: pointer;            /* Show pointer cursor on hover */
}

.spec-item .learn-more-button {
  display: inline-block;
  text-align: center;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #2f5c69;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.8; /* Makes the button more transparent */
  transition: opacity 0.3s ease; /* Smooth transition for hover */
}

.spec-item .learn-more-button {
  display: block;
}

.spec-item .learn-more-button:hover {
  opacity: 1; /* Button becomes fully opaque on hover */
}