/* Card List Layout */
.card-list-modems {
  padding: 40px;
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
  gap: 20px; /* Add spacing between cards */
  justify-content: center;
}

/* Individual Card Styling */
.card-list-modems .card {
  display: flex;
  flex-direction: column; /* Stack content and image vertically */
  align-items: center;
  background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Fancy gradient */
  border: 1px solid #ddd; /* Light border for structure */
  border-radius: 20px; /* Smooth, rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  overflow: hidden;
  max-width: 280px; /* Max width for cards */
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

.card-list-modems .career {
  display: flex;
  flex-direction: column; /* Stack content and image vertically */
  align-items: center;
  background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Fancy gradient */
  border: 1px solid #ddd; /* Light border for structure */
  border-radius: 20px; /* Smooth, rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  overflow: hidden;
  max-width: 400px; /* Max width for cards */
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

/* Individual Card Styling */
.card-list-modems .card-beacon {
  display: flex;
  flex-direction: column; /* Stack content and image vertically */
  align-items: center;
  background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Fancy gradient */
  border: 1px solid #ddd; /* Light border for structure */
  border-radius: 20px; /* Smooth, rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  overflow: hidden;
  max-width: 300px; /* Max width for cards */
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
}

/* Card Hover Effect */
.card-list-modems .card:hover {
  transform: translateY(-10px); /* Smooth lift effect */
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  background: linear-gradient(145deg, #f7f7f7, #e8e8e8); /* Lighter gradient on hover */
}

/* Card Content */
.card-list-modems .card-content {
  padding: 30px;
}

.card-list-modems .card-title {
  font-size: 1.5rem; /* Larger title for emphasis */
  font-weight: bold;
  color: #2f6c69;
  margin-bottom: 15px;
  text-align: left;
}

/* Card Description Styling */
.card-list-modems .card-description {
  font-size: 1rem;
  color: #444;
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 50px;
  word-wrap: break-word; /* Ensure long words break to the next line if necessary */
  overflow-wrap: break-word; /* Ensure wrapping for long words */
}

/* Fancy Divider */
.card-list-modems .card-content::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #2f6c69, #2f6c69, #2f6c69);
}

/* Optional Button or Link */
.card-list-modems .card-content .card-link {
  display: inline-block;
  padding: 10px 20px;
  background: #003366;
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.card-list-modems .card-content .card-link:hover {
  background: #0055aa;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Card Image */
.card-list-modems .card-image {
  width: 100%; /* Make the image fill the width */
  height: 180px; /* Limit the max height */
  object-fit: contain; /* Ensure the image is fully visible without cropping */
  border-bottom: 2px solid #ddd; /* Subtle border to separate image and text */
  padding: 10px; /* Add padding around the image to avoid touching the card's borders */
}

/* Card Image */
.card-list-modems .card-image-beacon {
  width: 100%; /* Make the image fill the width */
  height: 250px; /* Limit the max height */
  object-fit: contain; /* Ensure the image is fully visible without cropping */
  border-bottom: 2px solid #ddd; /* Subtle border to separate image and text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-list {
    flex-direction: column;
    align-items: center;
  }

  .card {
    max-width: 90%; /* Cards take up more space on smaller screens */
  }

  .card-image {
    max-height: 200px; /* Adjust max height for smaller screens */
  }
}

/* Make the Link fill the card and be clickable */
.card-list-modems .card-link-wrapper {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Make sure the link doesn't change the text color */
  display: block; /* Allow the link to wrap the entire card */
}

/* Optional: You can also add hover effects to the entire card */
.card-list-modems .card-link-wrapper:hover {
  opacity: 0.8; /* Add a slight opacity effect when the user hovers over the card */
}
