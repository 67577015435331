/* Navbar Styling */
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

/* Default (Desktop) - Hide the menu icon on larger screens */
.menu-icon {
  display: none;  /* Hide hamburger menu by default (desktop version) */
}


/* Navbar Logo Adjustments */
.navbar-logo img {
  height: 60px; /* Adjust the height of the logo to fit within navbar */
  width: auto;  /* Automatically adjust width to maintain aspect ratio */
}

/* Menu Items Container */
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  transition: all 0.5s ease;
}

/* Navbar Item Styling */
.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

/* Navbar Links Styling */
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

/* Dropdown arrow visibility fix */
.nav-links i {
  margin-left: 8px; /* Adds space between text and arrow */
  display: inline-block; /* Ensures caret is visible */
}


/* Navbar Links Styling */
.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;  /* Smooth transition for color change */
}

/* Add hover effect for navbar links */
.nav-links:hover {
  background-color: #2f5c69;  /* Change background color on hover */
  color: white;  /* Ensure text color stays white */
  border-radius: 5px; /* Optional: rounded corners for a more stylish hover effect */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

/* For active links (if you want a different style when a link is selected) */
.nav-links.active {
  color: #1888ff;  /* Change color when the link is active */
  background-color: #fff; /* White background when active */
  border-radius: 5px;
}

/* Navbar Styling */
.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  position: fixed; /* Make navbar fixed */
  top: 0; /* Align to the top */
  left: 0;
  width: 100%; /* Full width */
  z-index: 1000; /* Ensure navbar is on top of other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better visibility */
}

/* Menu Items Container */
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
  transition: all 0.5s ease;
}

/*====================================================================== */
/* Mobile Menu and Toggle Button */
@media screen and (max-width: 960px) {
  .menu-icon {
    color: #fff; /* Forces the menu icon to remain white in mobile view */
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers the items */
    align-items: center;     /* Horizontally centers the items */
    width: 100%;
    height: 90vh;            /* Use 90% of the viewport height */
    position: absolute;
    top: 80px;               /* Below the navbar */
    left: -100%;             /* Hidden by default */
    opacity: 1;
    transition: all 0.5s ease;
    background: #242222;     /* Dark background for mobile menu */
    z-index: 1;              /* Ensure it's above other content */
  }

  .nav-menu.active {
    left: 0; /* Show menu */
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 20%);
  }

  .nav-links {
    text-align: center;       /* Center align links */
    display: block;           /* Block for full-width click area */
    width: 100%;              /* Occupy full width */
    padding: 1.5rem 0;        /* Add padding for clickable area */
    line-height: 1.2;         /* Adjust line spacing for tighter appearance */
  }

  .nav-links:hover {
    background-color: #1888ff; /* Highlight on hover */
    border-radius: 0;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
    transition: 250ms;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
  }

  .nav-item {
    width: 100%;              /* Full width for each menu item */
    text-align: center;
    padding: 1rem 0;          /* Space out the items */
    position: relative;       /* Ensure dropdowns show up above other content */
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #242222;
    padding: 10px 0;
    z-index: 10;
  }

  .nav-item:hover .dropdown-menu {
    display: block;           /* Show dropdown on hover */
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  button {
    display: none;
  }
}

