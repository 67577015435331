/* Global Reset */
body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
}

hr {
  border-top: 2px solid #ccc; /* Adjust border thickness and color as needed */
  margin-top: 50px; /* Adjust margins as needed */
  margin-bottom: 0px;
}

/* Section Styling */
.welcome-section {
  padding: 0px 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-out {
  max-width: 1400px;
  margin: 0 auto;
  margin-top : 100px;
  padding-top: 10px;
  padding-bottom: 40px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
  background: linear-gradient(145deg, #f7f7f7, #e8e8e8); /* Lighter gradient on hover */
}

/* Title and Intro */
.title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: justify;
  margin-top: 50px;
  color: #2f5c69; /* Deep blue for professionalism */
}

.intro {
  font-size: 1.2rem;
  text-align: justify;
  margin-top: 50px;
  color: #555; /* Neutral gray for secondary text */
}

/* Expertise Section */
.subtitle {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 50px;
  color: #2f5c69;
}

.expertise {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.expertise-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.expertise-item {
  flex: 1 1 calc(33.333% - 20px); /* 3 columns */
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.expertise-item-2 {
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;

  flex: 1 1 100%; /* Full width for single-column layout */
  max-width: 600px; /* Optional: Restrict width to look balanced */
  margin: 0 auto; /* Center-align single item */
}

.expertise-item h3,
.expertise-item-2 h3 {
  font-size: 1.2rem;
  color: #2f5c69;
  margin-bottom: 10px;
}

.expertise-item p,
.expertise-item-2 p {
  font-size: 1.1rem;
  color: #555;
}

/* Call to Action */
.call-to-action {
  margin-top: 50px;
  text-align: justify;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2f5c69; 
}

/* Call to Action */
.call-to-contact {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 50px;
  color: #2f5c69; 
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .expertise-item {
    flex: 1 1 100%; /* Stack items on smaller screens */
  }
}
